import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting started`}</h1>
    <p>{`Processing a claim or payment transaction with Medipass requires three steps:`}</p>
    <ol>
      <li parentName="ol">{`Setup the Transaction SDK`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/sdk/transaction/getting-started/submit/"
        }}>{`Submit transaction details`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/sdk/transaction/getting-started/record/"
        }}>{`Record transaction outcome`}</a></li>
    </ol>
    <h2 {...{
      "id": "setup-the-transaction-sdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup-the-transaction-sdk",
        "aria-label": "setup the transaction sdk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup the Transaction SDK`}</h2>
    <h3 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requirements`}</h3>
    <p>{`The Transaction SDK requires a valid `}<strong parentName="p">{`API Key`}</strong>{`, `}<strong parentName="p">{`App ID`}</strong>{` and `}<strong parentName="p">{`App Version`}</strong>{`. It is also recommended that you set the `}<strong parentName="p">{`Environment`}</strong>{` to staging when testing the SDK in development.`}</p>
    <h4 {...{
      "id": "environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#environment",
        "aria-label": "environment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Environment`}</h4>
    <p>{`Two environments are available:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`stg`}</strong>{`: our staging / testing environment. This represents a production like environment but uses test account details and stubs to simulate responses from funders. By default, some external notifications like SMS are normally disabled but can be enabled via help desk request.`}</li>
      <li parentName="ul"><strong parentName="li">{`prod`}</strong>{`: our production environment.`}</li>
    </ul>
    <h4 {...{
      "id": "api-keys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#api-keys",
        "aria-label": "api keys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`API keys`}</h4>
    <p>{`An API key grants access to a business and all related providers and resources including practices, staff members, claims, payments and patients data. API keys are unique to a business account in Medipass. For example, an allied healthcare business “Always Healthy Pty Ltd” with four practice locations and 20 providers can use a single API key to create claims and access remittance details across the business.`}</p>
    <h5 {...{
      "id": "how-do-i-create-an-api-key",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#how-do-i-create-an-api-key",
        "aria-label": "how do i create an api key permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I create an API key?`}</h5>
    <p>{`The API key can only be created by the Business Admin using the Medipass portal.`}</p>
    <ol>
      <li parentName="ol">{`As a Business Admin, login to the Medipass portal: `}<a parentName="li" {...{
          "href": "https://connect.medipass.io"
        }}>{`https://connect.medipass.io`}</a></li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Medipass account`}</strong>{` in the bottom left hand corner`}</li>
      <li parentName="ol">{`In the `}<strong parentName="li">{`API Keys`}</strong>{` panel, select `}<strong parentName="li">{`Generate API Key`}</strong></li>
      <li parentName="ol">{`The API Key will be displayed`}</li>
    </ol>
    <p>{`As the API key is specific to a business and can be used to access sensitive resources, it should be stored securely in your system - preferably via strong encryption with associated key management.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: The Business Admin API key carries many privileges and should remain secret and not exposed to end users or on client-side code. We provide a specially permissioned short-lived SDK authentication token which can be used to process transactions.`}</p>
    </blockquote>
    <h4 {...{
      "id": "app-ids",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#app-ids",
        "aria-label": "app ids permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`App IDs`}</h4>
    <p>{`An App ID allows us to identify who you are, so Medipass can debug problems & pinpoint where issues are arising from. You can choose to specify a single application name or generate different App IDs for each version of your software. Your App ID must be registered with Medipass. `}<strong parentName="p">{`Contact Medipass via `}<a parentName="strong" {...{
          "href": "mailto:support@medipass.com.au"
        }}>{`support@medipass.com.au`}</a>{` or via chat to apply for your App ID.`}</strong></p>
    <h4 {...{
      "id": "app-version",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#app-version",
        "aria-label": "app version permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`App Version`}</h4>
    <p>{`The application version can be set to a value of your choosing. Ideally, this is aligned to your software release version and assists Medipass with debug and support inquires.`}</p>
    <h4 {...{
      "id": "domain-whitelist",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#domain-whitelist",
        "aria-label": "domain whitelist permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Domain whitelist`}</h4>
    <p>{`For security reasons, Medipass will also need to whitelist your origin domain names. Please `}<strong parentName="p">{`contact `}<a parentName="strong" {...{
          "href": "mailto:support@medipass.com.au"
        }}>{`support@medipass.com.au`}</a></strong>{` so we can add your domains to our content security policy. Until registered, you will get a CORS error for any unregistered originating domains.`}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h3>
    <p>{`The Transaction SDK can be installed via NPM/Yarn or downloaded via `}<a parentName="p" {...{
        "href": "https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"
      }}>{`unpkg.com`}</a>{`. If you plan to use the SDK as a JavaScript module, then install the NPM/Yarn package. If you plan to use the SDK with a `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag or other URL direction method, then use the unpkg.com version.`}</p>
    <p><strong parentName="p">{`Install via NPM/Yarn`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`npm install @medipass/partner-sdk
// or, with yarn:
yarn add @medipass/partner-sdk
`}</code></pre>
    <p><strong parentName="p">{`Download via unpkg.com`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"
      }}>{`https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js`}</a></p>
    <h4 {...{
      "id": "using-the-javascript-module",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#using-the-javascript-module",
        "aria-label": "using the javascript module permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the JavaScript Module`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import medipassSDK from '@medipass/partner-sdk';
// or: const medipassSDK = require('@medipass/partner-sdk');

medipassSDK.setConfig({
  env: 'stg',
  apiKey: '[insert your API key here]',
  appId: '[insert your App ID here]',
  appVersion: '[insert your App version here]'
});

medipassSDK.renderCreateTransaction({}, {
  onSuccess: function (transaction) {
    // handle successful submission of transaction
  },
  onError: function (error) {
    // handle errored submission of transaction
  },
  onCancel: function () {
    // handle when create transaction flow has been cancelled
  }
});
`}</code></pre>
    <h4 {...{
      "id": "using-a-script-tag",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#using-a-script-tag",
        "aria-label": "using a script tag permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using a `}<inlineCode parentName="h4">{`<script>`}</inlineCode>{` tag`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
<head>
  <script src="https://unpkg.com/@medipass/partner-sdk/umd/@medipass/partner-sdk.min.js"></script>
</head>
<body>
  <script>
    MedipassTransactionSDK.setConfig({
      env: 'stg',
      apiKey: '[insert your API key here]',
      appId: '[insert your App ID here]',
      appVersion: '[insert App version key here]'
    });

    MedipassTransactionSDK.renderCreateTransaction({}, {
      onSuccess: data => {
        // handle success
      },
      onError: data => {
        // handle error
      },
      onCancel: () => {
        // handle cancel
      }
    });
  </script>
</body>
</html>
`}</code></pre>
    <h3 {...{
      "id": "short-lived-sdk-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#short-lived-sdk-token",
        "aria-label": "short lived sdk token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Short-lived SDK token`}</h3>
    <p>{`Although the Business Admin API token can be used to directly process SDK transactions, the key could be obtained by lesser privileged users, such as a practice manager, provider or analyst. Those users could potentially decompile the SDK and look for this static API key.`}</p>
    <p>{`To mitigate risks of unauthorised Business Admin API key disclosure, we provide a short-lived SDK authentication token option.`}</p>
    <p>{`To use the short-lived token:`}</p>
    <ol>
      <li parentName="ol">{`On your server-side, use the Business Admin API key to call:`}</li>
    </ol>
    <p><strong parentName="p">{`Resource`}</strong></p>
    <p>{`POST `}<inlineCode parentName="p">{`<base-url>/v3/auth/token`}</inlineCode></p>
    <p>{`Where base-url:`}</p>
    <p>{`Staging: stg-api-au.medipass.io`}</p>
    <p>{`Production: api-au.medipass.io`}</p>
    <p><strong parentName="p">{`Required headers`}</strong></p>
    <ul>
      <li parentName="ul">{`authorization: `}<inlineCode parentName="li">{`Bearer <Business_Admin_API_key>`}</inlineCode></li>
      <li parentName="ul">{`x-appid: your App ID`}</li>
    </ul>
    <p><strong parentName="p">{`Payload`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`{
    "audience": "aud:business-sdk",
    "expiresIn": "1h"    // e.g. 30m, 1h, 6h, 24h. Defaults to 1h, max is 24h.
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The response will contain a short-lived token that is restricted to SDK features. For example, it can't be used to generate more tokens, update business details or create other users in a business.`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`{
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXIiOjEsImlhdCI6MTU5Mzc1NzI1MCwiZXhwIjoxNTkzNzYwODUwLCJhdWQiOiJhdWQ6YnVzaW5lc3Mtc2RrIiwic3ViIjoiNTc0M2NiNWI1YjI0Y2MxNDAwOTk5MTcwIiwianRpIjoiNVctZDZLIn0.YbbogFjmk7-BT15aY7vqHfFcXpH5Smr9LT96hHUjlWQ"
}
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Return this token to your portal, and use it for the SDK `}<strong parentName="li">{`apiKey`}</strong>{` field.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      